import { stripUrl } from 'src/utils/stripUrl'
import { Image } from 'src/components/ui/Image'

import ServicesInfo from './servicesInfo.json'

export const StoreServices = () => {
  return (
    <section className="flex flex-col items-center pt-20 font-roboto pb-[104px] sm:mt-24 vs:px-6 vs:pt-0 vs:pb-24">
      <div className="flex items-center justify-center flex-col mb-12">
        <h1 className="text-6xl italic font-bold uppercase text-[#344450] mb-4 vs:text-center vs:text-[42px] vs:mt-20 vs:mb-4">
          Serviços Disponíveis
        </h1>
        <p className="text-lg font-normal text-[#4E5D6B] max-w-[564px] vs:text-base">
          Para maiores informações entre em contato com a nossa loja e consulte
          a disponibilidade e prazo de entrega dos serviços.
        </p>
      </div>
      <div className="flex flex-wrap justify-center gap-[92px] vs:gap-6 max-w-[1440px] xl:max-w-[1320px] w-full">
        {ServicesInfo.map((item, index: number) => {
          return (
            <div
              className="flex items-start max-w-[286px] h-auto gap-[14px] mr-[92px] xl:mr-0 vs:max-w-[312px] vs:mr-0"
              key={index}
            >
              <Image
                src={stripUrl(item.ico)}
                alt={item.alt}
                loading="lazy"
                width={item.width}
                height={item.height}
              />
              <h2 className="text-base font-normal text-[#1A2A34] items-center">
                {item.title}
                <p className="text-sm font-normal text-[#4E5D6B]">
                  {item.description}
                </p>
              </h2>
            </div>
          )
        })}
      </div>
    </section>
  )
}
