import axios from 'axios'
import { useState, useEffect, useCallback } from 'react'

import { StoreContact } from './StoreContact'
import { StoreServices } from './StoreServices'
import type { StoresProps } from '../types/index'

import '../styles.scss'

export const EachStoreInfo = ({ slug }: { slug: string }) => {
  const [stores, setStores] = useState<StoresProps[]>([])

  const getStoreData = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `https://decathlonstore.myvtex.com/api/dataentities/SL/search?_where=(pageLink="/lojas/${slug}")&_fields=store_id,address,openingHours,name,services_ids,embed_link,address_complement,phone,specialMonth,specialHolidaySchedule,googleLink,pageLink,whatsapp,instagram,facebook,email`,
        {
          headers: {
            Accept: 'application/json',
            'REST-range': 'resources=0-5000',
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      )

      setStores(data)
    } catch (error) {
      console.error(error)
    }
  }, [slug])

  useEffect(() => {
    getStoreData()
  }, [getStoreData])

  return (
    <section className="bg-white">
      <div>
        {stores.map((item, index: number) => {
          return (
            <div key={index}>
              <StoreContact
                name={item?.name}
                address={item?.address}
                googleLink={item?.googleLink}
                phone={item?.phone}
                whatsapp={item?.whatsapp}
                email={item?.email}
                instagram={item?.instagram}
                facebook={item?.facebook}
                openingHours={item?.openingHours}
                specialHolidaySchedule={item?.specialHolidaySchedule}
              />
            </div>
          )
        })}

        {stores.map((storeMap, mapIndex: number) => {
          return (
            <div
              className="relative vs:pt-0 vs:px-6 vs:pb-6 bg-white"
              key={mapIndex}
            >
              {storeMap.embed_link.includes('iframe') ? (
                <span
                  className="iframe !w-full !h-[664px] vs:h-[204px] relative"
                  dangerouslySetInnerHTML={{ __html: storeMap.embed_link }}
                />
              ) : (
                <iframe
                  className="w-full h-[664px] vs:h-[204px] relative xl:mt-[-150px] vs:mt-0"
                  title="store-map"
                  loading="lazy"
                  frameBorder="0"
                  style={{ border: 0 }}
                  src={storeMap.embed_link}
                />
              )}
              <span className="absolute bg-[#2A3E85] w-full h-[664px] top-0 opacity-[12%] vs:hidden" />
            </div>
          )
        })}
        <div>
          <StoreServices />
        </div>
      </div>
    </section>
  )
}
