import { stripUrl } from 'src/utils/stripUrl'
import { Image } from 'src/components/ui/Image'
import Link from 'src/components/ui/Link'

import type { StoreContactProps } from '../types/index'
import { formatPhoneNumber } from '../FormatPhoneNumber'

import '../styles.scss'

export const StoreContact = ({
  name,
  address,
  googleLink,
  phone,
  whatsapp,
  email,
  instagram,
  facebook,
  openingHours,
  specialHolidaySchedule,
}: StoreContactProps) => {
  function alignOpeningHours(text: string | undefined) {
    const textOpeningHours = JSON.stringify(text)
      .replace(/\\r/g, ' ')
      .replace(/\\n/g, '')
      .replace(/"/g, '')

    return textOpeningHours
  }

  return (
    <section className="store-contact_container max-w-[756px] w-full bg-white rounded-lg h-auto vs:h-auto pt-20 px-[60px] pb-[60px] vs:pt-14 vs:px-6 vs:pb-6 absolute z-[1] top-[20%] left-[2%] vs:unset">
      <h1 className="text-6xl italic font-bold text-[#1A2A34] font-roboto m-0 uppercase mb-[24px] vs:text-[42px] vs:leading-[44px] vs:text-left">
        {name}
      </h1>
      <div className="flex items-center gap-[8px] pb-[32px] border-solid border-b-[1px] border-[#EFF1F3] vs:flex-col vs:items-start">
        <p className="text-xl font-bold text-[#4E5D6B] max-w-[368px] w-full vs:text-base">
          {address}
        </p>
        <Link
          href={googleLink}
          target="_blank"
          className="rounded border-2 border-solid border-[#D9DDE1] max-w-[219px] w-full flex items-center justify-center hover:!no-underline"
        >
          <p className="text-xl font-bold text-[#00689D] flex items-center gap-3">
            <Image
              src={stripUrl(
                'https://decathlonstore.vteximg.com.br/arquivos/Route.svg'
              )}
              alt="ico"
              loading="lazy"
              width={22}
              height={20}
            />
            Traçar rota
          </p>
        </Link>
      </div>
      <div className="flex justify-between vs:flex-col pt-8 vs:max-h-[310px]">
        <div
          className={`${
            phone === null &&
            whatsapp === null &&
            email === null &&
            instagram === null &&
            facebook === null
              ? 'hidden'
              : 'vs:pb-14'
          }`}
        >
          <p className="text-lg font-bold text-[#1A2A34] mb-4">
            Contato para Atendimento
          </p>
          <div
            className={
              phone ? 'flex items-center mb-6 gap-[10px] vs:mb-4' : 'hidden'
            }
          >
            <Image
              src={stripUrl(
                'https://decathlonstore.vteximg.com.br/arquivos/Phone.svg'
              )}
              alt="ico"
              loading="lazy"
              width={18}
              height={18}
            />
            <Link
              className="text-base font-normal !text-[#1A2A34] !no-underline !p-0"
              href={`tel: ${formatPhoneNumber(phone)}`}
              target="_blank"
            >
              {formatPhoneNumber(phone)}
            </Link>
          </div>
          <div
            className={
              whatsapp ? 'flex items-center mb-6 gap-[10px] vs:mb-4' : 'hidden'
            }
          >
            <Image
              src={stripUrl(
                'https://decathlonstore.vteximg.com.br/arquivos/Whats.svg'
              )}
              alt="ico"
              loading="lazy"
              width={20}
              height={20}
            />
            <Link
              className="text-base font-normal !text-[#1A2A34] !no-underline !p-0"
              href={`https://api.whatsapp.com/send?phone=${whatsapp}`}
              target="_blank"
            >
              {formatPhoneNumber(whatsapp)}
            </Link>
          </div>
          <div
            className={
              email ? 'flex items-center mb-6 gap-[10px] vs:mb-4' : 'hidden'
            }
          >
            <Image
              src={stripUrl(
                'https://decathlonstore.vteximg.com.br/arquivos/Gmail.svg'
              )}
              alt="ico"
              loading="lazy"
              width={20}
              height={20}
            />
            <Link
              className="text-base font-normal !text-[#1A2A34] !no-underline !p-0"
              href={`mailto:${email}`}
              target="_blank"
            >
              {email}
            </Link>
          </div>
          <div
            className={
              instagram ? 'flex items-center mb-6 gap-[10px] vs:mb-4' : 'hidden'
            }
          >
            <Image
              src={stripUrl(
                'https://decathlonstore.vteximg.com.br/arquivos/Instagram.svg'
              )}
              alt="ico"
              loading="lazy"
              width={20}
              height={20}
            />
            <Link
              className="text-base font-normal !text-[#1A2A34] !no-underline !p-0 lowercase"
              href={instagram}
              target="_blank"
            >
              @{name?.replace(/\s/g, '')}
            </Link>
          </div>
          <div
            className={
              facebook ? 'flex items-center mb-6 gap-[10px] vs:mb-4' : 'hidden'
            }
          >
            <Image
              src={stripUrl(
                'https://decathlonstore.vteximg.com.br/arquivos/Facebook.svg'
              )}
              alt="ico"
              loading="lazy"
              width={18}
              height={18}
            />

            <Link
              className="text-base font-normal !text-[#1A2A34] !no-underline !p-0 lowercase"
              href={facebook}
              target="_blank"
            >
              @{name?.replace(/\s/g, '')}
            </Link>
          </div>
        </div>
        <div className="max-w-[310px] w-full vs:mb-[100px]">
          <p className="text-lg font-bold text-[#1A2A34] mb-4">
            Horários de Funcionamento
          </p>
          <p
            className="text-base font-normal text-[#4E5D6B] max-w-[310px]"
            dangerouslySetInnerHTML={{
              __html: alignOpeningHours(openingHours),
            }}
          />
          <p className="text-base font-normal text-[#4E5D6B]">
            {specialHolidaySchedule}
          </p>
        </div>
      </div>
    </section>
  )
}
