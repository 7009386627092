import type { PageProps } from 'gatsby'
import { EachStoreInfo } from 'src/components/OurStores/EachStoreInfo/EachStoreInfo'

const OurStores = ({ params }: PageProps) => {
  const { slug } = params

  return (
    <>
      <EachStoreInfo slug={slug} />
    </>
  )
}

export default OurStores
